<template>
  <div role="footer">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-3" v-for="(item, i) in data" :key="i">
            <div class="widget">
              <h5 v-if="item.some(subItem => 'headline' == subItem.type)">
                {{ item.find(subItem => 'headline' == subItem.type).name }}
              </h5>
              <div v-if="item.some(subItem => 'qrcode' == subItem.type)" role="qrcode">
              </div>
              <div v-for="(subItem, j) in item.filter(item => 'headline' !== item.type)" :key="j">
                <ul>
                  <li v-if="subItem.url">
                    <a v-if="subItem.external" :href="subItem.url" :target="`${subItem.target || '_self'}`">
                      {{subItem.name}}
                    </a>
                    <router-link v-else :to="subItem.url">{{subItem.name}}</router-link>
                  </li>
                  <li v-else>
                    {{subItem.name}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subfooter">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="de-flex">
                <div class="de-flex-col">
                  &copy; Copyright {{year}} - 润标天泽国际认证（北京）有限公司
                </div>
                <div class="de-flex-col">
                  备案号:
                  <div class="social-icons">
                    <a href="#">
                      京ICP备05028192号-4
                    </a>
                    <a href="#">
                      京卫网审第1045号
                    </a>
                    <a href="#">
                      京公网安备110402430084号
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "GlobalFooter",
  props: {
    data: {
      type: Array,
      default: () => ([
        [
          {
            type: "headline",
            name: "健康管理"
          },
          {
            name: "系统首页",
            url: "/",
            target: "_self"
          },
          {
            name: "课程中心",
            url: "/courseList"
          },
          {
            name: "预约考核",
            url: "/assessment"
          },
          {
            name: "模拟考核",
            url: "/examination"
          },
          {
            name: "线下培训",
            url: "/train"
          },
          {
            name: "认证机构",
            url: "/"
          },
          {
            name: "新闻资讯",
            url: "/Journalism"
          },
          {
            name: "系统公告",
            url: "/systemAnnouncement"
          }
        ],
        [
          {
            type: "headline",
            name: "便捷服务"
          },
          {
            name: "帮助中心",
            url: "/help"
          },
          {
            name: "证书查询",
            url: "/certificateQuery"
          },
          {
            name: "教师登录",
            url: "/teacherReview"
          }
        ],
        [
          {
            type: "headline",
            name: "联系我们"
          },
          {
            name: "健康管理委员会"
          },
          {
            name: "010-8639-9862"
          },
          {
            name: "hqcc2016@126.com",
            url: "mailto:hqcc2016@126.com",
            external: true
          }
        ],
        [
          {
            type: "headline",
            name: "关注我们"
          },
          {
            type: "qrcode",
            url: ""
          }
        ]
      ])
    }
  },
  computed: {
    year(){
      const
        now = new Date(),
        year = now.getFullYear()
      ;
      return year;
    }
  },
  mounted(){
  
  }
};
</script>

<style lang="less" scoped>
.social-icons a:hover {
  color: @secondary-color;
}
footer [role="qrcode"]{
  width: 120px;
  height: 120px;
  background: url("../../static/images/hqccpc.jpg") no-repeat left top;
  background-size: contain;
}
</style>