<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<style lang="less">
  @import "./static/common";
</style>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data () {
    return {
      locale: zhCN,
    }
  },
  created(){
    new WOW().init();
  }
}
</script>
