<template>
  <a-spin :spinning="loading">
    <div v-if="list.length">
      <slot />
    </div>
    <div v-else style="padding:80px 0">
      <a-empty v-if="!loading" />
    </div>
  </a-spin>
</template>

<script>
export default {
  name: 'listBox',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style>
.ant-spin-nested-loading {
  width: 100% !important;
}
</style>