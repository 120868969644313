import Vue from "vue";
import VueRouter from "vue-router";
import BasicLayout from "@/components/layout/basic-layout";

Vue.use(VueRouter);

const routes = [
    {
      path: "/",
      name: "home",
      component: BasicLayout,
      children: [
        {
          path: "/",
          name: "home",
          component: () => import(/* webpackChunkName: "home" */ "../views/home.vue")
        },
        {
          path: "/loggingIn",
          name: "loggingIn",
          component: () => import(/* webpackChunkName: "loggingIn" */ "../views/loggingIn.vue")
        },
        {
          path: "/login",
          name: "login",
          component: () => import(/* webpackChunkName: "login" */ "../views/login.vue")
        },
        // {
        //   path: "/faq",
        //   name: "faq",
        //   component: () => import(/* webpackChunkName: "faq" */ "../views/faq.vue")
        // },
        {
          path: "/courseList",
          name: "courseList",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseList.vue")
        },
        {
          path: "/assessment",
          name: "assessment",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/assessment.vue")
        },
        {
          path: "/examination",
          name: "examination",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/examination.vue")
        },
        {
          path: "/certificate",
          name: "certificate",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/certificate.vue")
        },
        {
          path: "/Journalism",
          name: "Journalism",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/Journalism.vue")
        },
        {
          path: "/train",
          name: "train",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/train.vue")
        },
        {
          path: "/personal",
          name: "personal",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/personal.vue")
        },
        {
          path: "/help",
          name: "help",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/help.vue")
        },
        {
          path: "/certificateDetails",
          name: "certificateDetails",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/certificateDetails.vue")
        },
        {
          path: "/certificateQuery",
          name: "certificateQuery",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/certificateQuery.vue")
        },
        {
          path: "/courseDetails",
          name: "courseDetails",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseDetails.vue")
        },
        {
          path: "/lecturer",
          name: "lecturer",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/lecturer.vue")
        },
        {
          path: "/systemAnnouncement",
          name: "systemAnnouncement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/systemAnnouncement.vue")
        },
        {
          path: "/essentialInformation",
          name: "essentialInformation",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/essentialInformation.vue")
        },
        {
          path: "/myCourse",
          name: "myCourse",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myCourse.vue")
        },
        {
          path: "/myAssessment",
          name: "myAssessment",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myAssessment.vue")
        },
        {
          path: "/myOrder",
          name: "myOrder",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myOrder.vue")
        },
        {
          path: "/myGrades",
          name: "myGrades",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myGrades.vue")
        },
        {
          path: "/myCertificate",
          name: "myCertificate",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myCertificate.vue")
        },
        {
          path: "/myMockExam",
          name: "myMockExam",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myMockExam.vue")
        },
        {
          path: "/myOfflineTraining",
          name: "myOfflineTraining",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myOfflineTraining.vue")
        },
        {
          path: "/myMistakeBook",
          name: "myMistakeBook",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myMistakeBook.vue")
        },
        {
          path: "/myStudyRecord",
          name: "myStudyRecord",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myStudyRecord.vue")
        },
        {
          path: "/downloadMyInformation",
          name: "downloadMyInformation",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/downloadMyInformation.vue")
        },
        {
          path: "/myCollection",
          name: "myCollection",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myCollection.vue")
        },
        {
          path: "/myAttention",
          name: "myAttention",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myAttention.vue")
        },
        {
          path: "/teacherReview",
          name: "teacherReview",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/teacherReview.vue")
        },
        {
          path: "/teacherManagement",
          name: "teacherManagement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/teacherManagement.vue")
        },
        {
          path: "/teacherInformation",
          name: "teacherInformation",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/teacherInformation.vue")
        },
        {
          path: "/myFans",
          name: "myFans",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/myFans.vue")
        },
        {
          path: "/uploadCourseware",
          name: "uploadCourseware",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/uploadCourseware.vue")
        },
        {
          path: "/coursewareDataManagement",
          name: "coursewareDataManagement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/coursewareDataManagement.vue")
        },
        {
          path: "/courseInformation",
          name: "courseInformation",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseInformation.vue")
        },
        {
          path: "/courseManagement",
          name: "courseManagement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseManagement.vue")
        },
        {
          path: "/messageList",
          name: "messageList",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/messageList.vue")
        },
        {
          path: "/whole",
          name: "whole",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/whole.vue")
        },
        {
          path: "/courseVideo",
          name: "courseVideo",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseVideo.vue")
        },
        {
          path: "/courseAudio",
          name: "courseAudio",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseAudio.vue")
        },
        {
          path: "/courseGraphics",
          name: "courseGraphics",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseGraphics.vue")
        },
        {
          path: "/courseManagementTable",
          name: "courseManagementTable",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseManagementTable.vue")
        },
        {
          path: "/courseStructure",
          name: "courseStructure",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/courseStructure.vue")
        },
        {
          path: "/mixedMaterialItem",
          name: "mixedMaterialItem",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ "../views/mixedMaterialItem.vue")
        },
      ]
    }
  ],

  router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

  router.beforeEach(function (to, from, next) {
    next();
    // let token = window.localStorage.getItem('token')
    // if (token) {
    //   next();
    // }else{
    //   if (to.path !== '/login') {
    //     next({ path: '/login' })
    //   } else {
    //     next()
    //   }
    // }
    // next();
    new WOW().init();
  });

  router.afterEach(
    (to, from) => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  )

export default router;
