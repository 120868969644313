import axios from "@/utils/axios";

// user/updateInfo
export const updateInfo = params => axios.post(
    "user/updateInfo",
    params,
    1
)

// user/getVerifyCode
export const getVerifyCode = params => axios.get(
    "user/getVerifyCode",
    params
)

// user/getCurrentUser
export const getCurrentUser = params => axios.get(
    "user/getCurrentUser",
    params
)

// /tokenTest/createToken
export const createToken = params => axios.get(
    "tokenTest/createToken",
    params
)

// taFavorites/page
export const favoritesPage = params => axios.get(
    "taFavorites/page",
    params
)

// taUserFollow/list
export const followList = params => axios.get(
    "taUserFollow/list",
    params
)