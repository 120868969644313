<template>
  <div>
    <div id="wrapper">
      <global-header />
      <router-view />
      <global-footer />
    </div>
  </div>
</template>

<script>
import GlobalHeader from "@/components/global-header";
import GlobalFooter from "@/components/global-footer";
export default {
  name: "BasicLayout",
  components: {
    GlobalHeader,
    GlobalFooter
  }
};
</script>