import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    globalState: {
      subHeader: false,
      headerSearch: true
    }
  },
  getters: {
    globalState: state => state.globalState
  },
  mutations: {
    // $_G: (state, k, v) => state.globalState[k] = v
    SET_SUBHEADER: (state, value) => state.globalState.subHeader = value,
    SET_HEADERSEARCH: (state, value) => state.globalState.headerSearch = value
  },
  actions: {
  },
  modules: {
  }
});
