import axios from "axios";
import { domainURL } from "@/configs";
import {Modal, notification} from 'ant-design-vue'

// 基础配置
axios.defaults.baseURL = domainURL;
axios.defaults.withCredentials = true;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem("token");
    config.headers.device = 2;
    if (token) {
      config.headers.token = token;
    } else {
      console.log("没有token,请先登录");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.code >= 70001 && response.data.code <= 79999) {
      // code [70001-79999]为token错误，应当返回登录界面重新获取token(重新登录)
      Modal.error({
        title: "登录已过期",
        content: "很抱歉，登录已过期，请重新登录",
        okText: "重新登录",
        mask: false,
        onOk: () => {
          // console.log("重新登录");
          let url = `${domainURL}/entry/wechatWebsiteRender`;
          window.location.href = url;
        },
      });
      return;
    } else if (response.data.code >= 40001 && response.data.code <= 49999) {
      // code=40001，message=系统繁忙，请稍后重试，此错误为未捕获的异常信息，如看到可通知后端进行此异常的捕获处理。
      // 被捕获的异常信息[code一般为40001-49999]会通过message返回给前端。
      notification.error({ message: '系统提示', description: response.data.message, duration: 4})
    } else if (response.data.code != 200 && response.status != 200) {
      // code=200为成功，若code != 200，则一定是失败，把提示信息(message)要显示给用户界面。
      notification.error({ message: '系统提示', description: response.data.message, duration: 4})
    }
    if (response.data.newJToken) {
      let newJToken = response.data.newJToken;
      window.localStorage.setItem("token", newJToken.token);
    }
    return response.data;
  },
  (error) => Promise.reject(error)
);