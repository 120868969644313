<template>
  <div role="header">
    <div id="topbar" class="text-light topbar-noborder">
      <div class="container">
        <div class="topbar-left sm-hide">
          <span class="topbar-widget tb-social">
            <span class="topbar-widget">欢迎来到 HQCC 健康管理系统!</span>
          </span>
        </div>
        <div class="topbar-right">
          <div class="topbar-right">
            <span
              class="topbar-widget"
              v-for="(item, i) in topbar"
              :key="i"
              @click="onChange(i)"
            >
              <a
                v-if="item.external"
                :href="item.url"
                :target="`${item.target || '_self'}`"
              >
                {{ item.name }}
              </a>
              <router-link v-else :to="item.url"
                ><div :class="i == tabIndex ? 'tab-text' : ''">
                  {{ item.name }}
                </div></router-link
              >
            </span>
            <div
              @click="onThirdLogin('wechat_open')"
              title="微信"
              class="topbar-widget login-box"
              v-if="JSON.stringify(userInfo) == '{}'"
            >
              登录
            </div>
            <a-dropdown placement="bottomCenter" v-else>
              <div class="topbar-widget">{{ userInfo.realname }}</div>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <div
              @click="mockLogin()"
              class="topbar-widget login-box"
              v-if="env == 'development'"
            >
              模拟登录
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <header :class="`${globalState.subHeader ? 'transparent' : 'smaller'}`">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="de-flex sm-pt10">
              <div class="de-flex-col">
                <!-- logo begin -->
                <div id="logo">
                  <a href="/">
                    <img alt="" class="logo" :src="logo" />
                    <img alt="" class="logo-2" src="images/logo.png" />
                  </a>
                </div>
                <!-- logo close -->
              </div>
              <div class="de-flex-col header-col-mid">
                <!-- mainmenu begin -->
                <ul id="mainmenu">
                  <li v-for="(item, i) in menu" :key="i" @click="handleClick">
                    <a
                      v-if="item.external"
                      :href="item.url"
                      :target="`${item.target || '_self'}`"
                    >
                      {{ item.name }}
                    </a>
                    <router-link v-else :to="item.url">{{
                      item.name
                    }}</router-link>
                    <template v-if="item.children">
                      <ul>
                        <li v-for="(subItem, j) in item.children" :key="j">
                          <a
                            v-if="subItem.external"
                            :href="subItem.url"
                            :target="`${subItem.target || '_self'}`"
                            >{{ subItem.name }}</a
                          >
                          <router-link v-else :to="item.url">{{
                            subItem.name
                          }}</router-link>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
              <div class="de-flex-col" v-if="globalState.headerSearch">
                <form
                  action="javascript:;"
                  class="row"
                  id="form_subscribe"
                  method="post"
                  name="form_subscribe"
                >
                  <div class="col text-center">
                    <input
                      class="form-control"
                      id="name_1"
                      name="name_1"
                      placeholder="输入课程关键字搜索"
                      type="text"
                    />
                    <a href="javascript:;" id="btn-submit">
                      <i class="fa fa-search bg-color-secondary"></i>
                    </a>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { ThirdLogins } from "@/mixins/third-login";
import { domainURL } from "@/configs";
import { createToken, getCurrentUser } from "@/api/personal";

import { logo } from "@/configs/index.js";
export default {
  name: "GlobalHeader",
  // mixins: [
  //   ThirdLogins
  // ],
  computed: {
    ...mapGetters(["globalState"]),
    logo(){
      return `images/${logo || "logo-light.png"}`;
    }
  },
  props: {
    menu: {
      type: Array,
      default: () => [
        {
          name: "首页",
          url: "/",
        },
        {
          name: "课程中心",
          url: "/courseList",
        },
        {
          name: "预约考核",
          url: "/assessment",
        },
        {
          name: "模拟考试",
          url: "/examination",
        },
        {
          name: "等级证书",
          url: "/certificate",
        },
        {
          name: "新闻资讯",
          url: "/Journalism",
        },
        {
          name: "线下培训",
          url: "/train",
        },
        {
          name: "个人中心",
          url: "/personal",
        },
      ],
    },
    topbar: {
      type: Array,
      default: () => [
        {
          name: "教师登录",
          url: "/teacherReview",
        },
        {
          name: "管理员登录",
          external: true,
          target: '_blank',
          url: "https://htmanage.hqccglobal.com",
        },
        {
          name: "系统公告",
          url: "/systemAnnouncement",
        },
        {
          name: "帮助中心",
          url: "/help",
        },
        {
          name: "证书查询",
          url: "/certificateQuery",
        },
      ],
    },
  },
  data() {
    return {
      tabIndex: null,
      userInfo: {},
      env: process.env.NODE_ENV
    };
  },
  watch: {
    $route() {
      this.getUser();
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      let userInfo = window.localStorage.getItem("userInfo") || {};
      if (window.localStorage.getItem("token")) {
        if (JSON.stringify(userInfo) == "{}") {
          getCurrentUser({}).then((res) => {
            const { data } = res;
            this.userInfo = data;
            window.localStorage.setItem("userInfo", JSON.stringify(data));
          });
        } else {
          this.userInfo = JSON.parse(userInfo);
        }
      } else {
        this.userInfo = {};
      }
    },
    onChange(i) {
      const _self = this;
      _self.tabIndex = i;
    },
    handleClick() {
      const _self = this;
      _self.tabIndex = null;
    },
    onThirdLogin() {
      let url = `${domainURL}/entry/wechatWebsiteRender`;
      //window.open(url, `login ${source}`, 'height=500, width=500, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')
      window.location.href = url;
    },
    async mockLogin() {
      const userToken = await createToken({
        userId: "1553257893849993218", // 学生
        // userId: "1569242593110290434", // 讲师
      });
      this.$message.success("模拟登录成功");
      window.localStorage.setItem("token", userToken.data.token);
      const userData = await getCurrentUser({});
      window.localStorage.setItem("userInfo", JSON.stringify(userData.data));
      setTimeout(() => {
        location.reload();
      }, 200);
    },
    logout() {
      window.localStorage.clear();
      this.$message.success("退出登录成功");
      setTimeout(() => {
        location.reload();
      }, 500);
    }
  },
};
</script>

<style lang="less" scoped>
#topbar {
  position: relative;
  background-color: #2e153f;
}
header.smaller {
  position: static;
  &.transparent {
    top: 40px;
    position: absolute;
    background-color: transparent;
  }
  #mainmenu li {
    .router-link-exact-active {
      color: @secondary-color;
      &:after {
        content: "";
        position: relative;
        color: #ffffff;
        display: block;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 14px;
        border-bottom: solid 2px @secondary-color;
      }
    }
    li {
      &:hover > a.router-link-exact-active {
        color: #ffffff;
      }
      a.router-link-exact-active {
        color: currentColor;
        &:after {
          display: none;
        }
      }
    }
  }
}
.tab-text {
  color: #ff7600;
}
.login-box {
  cursor: pointer;
}
</style>
