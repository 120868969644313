import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Modal, message, notification, Button, Rate, Pagination, Radio, Checkbox, Input, Select, DatePicker, ConfigProvider, Upload, Icon, Table, Cascader, Empty, Spin, Carousel, Statistic, Dropdown, Menu, Progress } from "ant-design-vue";
import InstallComponents from '@/components/index'

Vue.use(Modal);
Vue.use(Button);
Vue.use(Rate);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(ConfigProvider);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(Table);
Vue.use(Cascader);
Vue.use(Empty);
Vue.use(Spin);
Vue.use(Carousel);
Vue.use(Statistic);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Progress);
Vue.use(InstallComponents);

Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
