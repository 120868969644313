import axios from "axios";
import qs from "qs";
import "@/configs/axios";

/**
 * @author hiroki.zhu
 * @desc 网络请求工具类
 * 处理数据,按通用格式返回
 * @param {Object} obj 原始数据返回格式
 */
class Axios {
  ajax(config = {}) {
    return axios(config);
  }

  get(url, config = {}) {
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    return axios.get(url, {
      params: config,
      withCredentials : true
    });
  }

  post(url, config = {}, form) {
    if (form === 1) {
      axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    }
    if(!form){
      config = qs.stringify(config);
    }
    return axios.post(url, config);
  }

  del(url, config = {}) {
    config = qs.stringify(config);
    return axios.delete(url, {
      data: config,
      withCredentials: true
    });
  }
}

export default new Axios();